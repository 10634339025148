// core version + navigation, pagination modules:
import Swiper, { Navigation } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';


var mainSliderSwiper = new Swiper(".mySwiper", {
    modules: [Navigation],
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    /* autoplay: {
        delay: 5000,
    }, */
    on:{                
        realIndexChange: function(){        
            $(".pre-slider-headline").addClass("slider-headline");
            $(".pre-learn-the-process").addClass("learn-the-process");
            $(".pre-header-slider").addClass("header-slider");
        } 
    }
});

if($(".mainSliderSwiper").length > 0){
        var mainSliderswiperContent = new Swiper(".mainSliderSwiper", {
            modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
                delay: 5000,
            }
    });

    //mainSliderSwiper.controller.control = mainSliderswiperContent;
    //mainSliderswiperContent.controller.control = mainSliderSwiper;
}

var swiper = new Swiper(".partnerSwiper", {
    modules: [Navigation],
    slidesPerView: 2,
    spaceBetween: 30,
    breakpoints: {                    
        768: {
            slidesPerView: 4,
        },
        1024: {
            slidesPerView: 35,
        },
    },
    autoplay: {
            delay: 3000,
    }
});

var newsSwiper = new Swiper(".newsSwiper", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    navigation: {
        nextEl: ".news-swiper-button-next",
        prevEl: ".news-swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
});

var eb5Swiper = new Swiper(".eb5Swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    navigation: {
        nextEl: ".eb5-swiper-button-next",
        prevEl: ".eb5-swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
});